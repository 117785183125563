@tailwind base;
@tailwind utilities;
@tailwind utilities;


.parent-siderbar{
    @apply hidden lg:flex lg:flex-col justify-evenly w-[17rem] h-screen bg-[#ffd9b4] text-black px-4 shadow-lg 
}
.logo-sidebar{
    @apply h-44 w-[85%] mx-auto text-black
}

.unorderlist-siderbar{
    @apply text-lg text-left flex flex-col space-y-4 text-black
}

.active{
    @apply py-2 px-4 rounded transition-colors  duration-300 ease-in-out  font-normal text-black bg-[#ffcc9a] 
}

.langs{
    @apply flex flex-row items-center justify-between w-full p-2 rounded bg-[#ffcc9a] focus:outline-none text-black
}

.parent-nav{
    @apply lg:hidden z-50 fixed top-0 left-0 w-full bg-[#ffd9b4] text-black shadow-lg 
}