@tailwind base;
@tailwind utilities;
@tailwind components;


body {
  @apply bg-[#003a1f] text-[#ffd9b4]
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
