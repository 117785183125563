@tailwind base;
@tailwind components;
@tailwind utilities;




/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap'); */
body{
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'Times New Roman', Times, serif;
  font-weight: normal;
  @apply bg-[#003a1f] text-[#ffd9b4]
}


 


/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}






.link{

  @apply py-2 px-4 rounded transition-colors duration-300 ease-in-out   font-normal   text-black
}


